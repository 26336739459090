import { cssVar, defineStyleConfig } from "@chakra-ui/react";

const $startColor = cssVar("skeleton-start-color");
const $endColor = cssVar("skeleton-end-color");

const Skeleton = defineStyleConfig({
    baseStyle: {
        [$startColor.variable]: "colors.gray.200",
        [$endColor.variable]: "colors.gray.300"
    }
});

export default Skeleton;
