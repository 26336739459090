import { menuAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(menuAnatomy.keys);
const baseStyle = definePartsStyle({
    button: {
        color: "ebonyClay.500",
        textDecor: "underline",
        _hover: {
            textDecor: "underline"
        },
        _disabled: {
            color: "gray.400",
            _hover: {
                cursor: "not-allowed",
                textDecor: "underline"
            }
        }
    },
    item: {
        _disabled: {
            color: "blue"
        }
    }
});
const Menu = defineMultiStyleConfig({ baseStyle });
export default Menu;
