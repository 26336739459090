import { tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(tabsAnatomy.keys);

const markingPortalStyle = definePartsStyle({
    tab: {
        backgroundColor: "gray.100 !important",
        border: "1px solid !important",
        borderBottomColor: "gray.300 !important",
        borderTopColor: "gray.300 !important",
        borderRightColor: "gray.300 !important",
        borderLeftColor: "gray.300 !important",
        borderTopLeftRadius: "md",
        borderTopRightRadius: "md",
        marginRight: "10px",
        fontSize: "12px !important",
        fontFamily: "inter, sans-serif",
        padding: "0.5rem 1rem",
        color: "deepBlue.500 !important",
        _selected: {
            backgroundColor: "white.500 !important",
            borderBottomColor: "white.500 !important",
            borderTopColor: "gray.300 !important",
            borderRightColor: "gray.300 !important",
            borderLeftColor: "gray.300 !important",
            color: "oliveBlack.500",
            fontWeight: "600"
        }
    },
    tablist: {
        border: "none !important",
        color: "deepBlue.500 !important",
    },
    tabpanels: {
        border: "1px solid",
        borderColor: "gray.300 !important",
        borderBottomLeftRadius: "md",
        borderBottomRightRadius: "md",
        padding: "1.5rem",
        marginTop: "-1px"
    }
});

const Tabs = defineMultiStyleConfig({
    variants: {
        markingPortal: markingPortalStyle
    }
});

export default Tabs;
