import { StyleProps } from "@chakra-ui/react";

export const checkoutInfoEditLayoutStyle: StyleProps = {
    mt: -1
};

const layerStyles: { [key: string]: StyleProps } = {
    checkoutInfoEditLayoutStyle
};

export default layerStyles;
