import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { defineMultiStyleConfig, definePartsStyle } =
    createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
    field: {
        fontSize: 15,
        borderRadius: 6,
        _placeholder: {
            color: "gray.500"
        },
        boxShadow:
            "0px 0px 1px 0px rgba(50, 50, 71, 0.20), 0px 1px 2px 0px rgba(50, 50, 71, 0.08)"
    }
});

const outlineStyle = definePartsStyle({
    field: {
        borderWidth: 1,
        borderColor: "gray.300",
        _focusVisible: {
            borderColor: "burntYellow.300",
            boxShadow: "0 0 0 1px var(--euka-colors-burntYellow-200)"
        },
        height: 12
    }
});

const Input = defineMultiStyleConfig({
    baseStyle,
    variants: { outline: outlineStyle }
});

export default Input;
