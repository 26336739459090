import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const baseStyle = defineStyle({
    color: "ebonyClay.500",
    fontSize: 16,
    textDecoration: "underline",
    _hover: {
        textDecoration: "underline"
    },
    _disabled: {
        color: "gray.400",
        _hover: {
            cursor: "not-allowed",
            textDecoration: "underline"
        }
    }
});
const Link = defineStyleConfig({
    baseStyle
});
export default Link;
