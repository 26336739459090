import Echo from "laravel-echo";
import Pusher from "pusher-js";

const isProd = () => process.env.NODE_ENV !== "development";

window.Pusher = Pusher;

export const echo = new Echo({
    broadcaster: "reverb",
    key: process.env.REVERB_APP_KEY ?? "mwazzphajvsvad3qyhli",
    secret: process.env.REVERB_APP_SECRET ?? "wnklrwwwsbkh4ywnyxfw",
    wsHost: window.location.hostname,
    encrypted: false,
    forceTLS: isProd(),
    enabledTransports: ["ws", "wss"]
});
