import { tagAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(tagAnatomy.keys);

const baseStyle = definePartsStyle({
    container: {
        alignItems: "center",
        textAlign: "center",
        bg: "gray.200",
        color: "gray.600",
        padding: "0.25rem 0.5rem",
        fontSize: "0.75rem",
        borderRadius: "0.25rem"
    }
});

const Tag = defineMultiStyleConfig({
    baseStyle,
    defaultProps: {
        colorScheme: "gray"
    }
});

export default Tag;
