import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
    container: {
        alignItems: "start"
    },
    control: {
        borderWidth: 2,
        borderColor: "gray.300"
    }
});

const mdStyle = definePartsStyle({
    label: {
        fontSize: 14,
        mt: -1
    }
});

const mpXlStyle = definePartsStyle({
    control: defineStyle({
        height: "48px",
        width: "48px",
        border: "none"
    })
});

const Checkbox = defineMultiStyleConfig({
    baseStyle,
    sizes: {
        md: mdStyle
    },
    defaultProps: {
        colorScheme: "burntYellow"
    },
    variants: { mpXlStyle }
});

export default Checkbox;
