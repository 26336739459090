import { CountryInterface } from "@/ts/interfaces/Checkout/CountryInterface";
import { ExistingCardInterface } from "@/ts/interfaces/Checkout/ExistingCardInterface";
import { usePage } from "@inertiajs/react";

import { GradeInterface } from "@ts/interfaces/Checkout/GradeInterface.ts";
import { LocaleInterface } from "@ts/interfaces/Checkout/LocaleInterface.ts";
import { UserInterface } from "@ts/interfaces/Parent/UserInterface";

const useCheckoutPageProps = () => {
    const props = usePage().props as unknown as {
        productFamilyUrl: string;
        hubspotEventEnrol: string;
        hubspotEventCheckout: string;
        emailAbstractApiKey: string;
        phoneAbstractApiKey: string;
        parentUser?: UserInterface;
        students: UserInterface[];
        addonTextbookLink?: string;
        existingCards?: ExistingCardInterface[];
        subjectsByGrade: GradeInterface[];
        productFamilyUrlsByCountry: string[];
        availableCountries: LocaleInterface[];
        allCountries: LocaleInterface[];
        countries?: CountryInterface[];
    };

    return props;
};

export default useCheckoutPageProps;
