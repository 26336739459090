import { defineStyle, defineStyleConfig, SystemStyleObject } from "@chakra-ui/react";

const baseStyle = {
    border: "1px solid",
    borderRadius: 4,
    px: 2,
    textTransform: "none",
    fontWeight: "normal",
    fontFamily: "secondary",
    lineHeight: "18px"
};

const success = defineStyle({
    bg: "success.100",
    borderColor: "success.500",
    color: "success.500"
});

const error = defineStyle({
    bg: "danger.100",
    borderColor: "danger.500",
    color: "danger.500"
});

const assignmentStatus = defineStyle((props: SystemStyleObject) => {
    const { colorScheme: c } = props;
    return {
        bg: `${c}`,
        border: "none"
    };
});

const Badge = defineStyleConfig({
    baseStyle,
    variants: { success, error, assignmentStatus },
    defaultProps: {
        variant: "success"
    }
});

export default Badge;
