import { defineStyleConfig } from "@chakra-ui/react";

const Drawer = defineStyleConfig({
    variants: {
        primary: {
            dialog: {
                backgroundColor: "white"
            }
        }
    }
});

export default Drawer;
