/* eslint-disable quote-props */
import { alertAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(alertAnatomy.keys);

const baseStyle = definePartsStyle({
    container: {
        borderRadius: 8,
        shadow: "rgba(12, 26, 75, 0.10), 0px 4px 20px -2px rgba(50, 50, 71, 0.08);",
        "&[data-status=success]": {
            bg: "success.100",
            border: "1px solid var(--euka-colors-success-500)",
            borderRadius: 8
        },
        "&[data-status=error]": {
            bg: "danger.100",
            border: "1px solid var(--euka-colors-danger-500)",
            borderRadius: 8
        }
    },
    icon: {
        "&[data-status=success]": {
            color: "success.500"
        },
        "&[data-status=error]": {
            color: "danger.500"
        }
    },
    title: {
        fontFamily: "heading"
    }
});

const Alert = defineMultiStyleConfig({
    baseStyle
});

export default Alert;
