import { Page, PageProps } from "@inertiajs/core";

export const getPageProps = () => {
    // Select the element with the `data-page` attribute
    const pageElement = document.querySelector("[data-page]");

    // Check if the element exists
    if (!pageElement) {
        console.warn("No element with data-page attribute found.");
        return null;
    }

    // Get the value of the `data-page` attribute
    const pageData = pageElement.getAttribute("data-page") ?? "";

    try {
        // Parse the JSON data
        const parsedData = JSON.parse(pageData) as Page<PageProps>;
        return parsedData.props;
    } catch (error) {
        // Log an error message if parsing fails
        console.warn("Error parsing JSON for page props:", error);
        return null;
    }
};
