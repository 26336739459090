const baseStyle = {
    stepper: {
        "&[data-orientation=horizontal]": {
            alignItems: "flex-start"
        }
    },
    step: {
        flexBasis: "auto"
    },
    indicator: {
        bg: "gray.300",
        borderColor: "gray.300",
        "&[data-status=complete]": {
            background: "#BEDD8D",
            borderWidth: "3px",
            borderColor: "#BEDD8D"
        },
        "&[data-status=active]": {
            background: "#FFFFFF",
            borderColor: "#BEDD8D",
            borderWidth: "3px"
        }
    }
};

const Stepper = {
    baseStyle
};

export default Stepper;
