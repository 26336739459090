import { initReactI18next } from "react-i18next";

import { getPageProps } from "@/utils/pageProps";
import i18next from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

const pageProps = getPageProps() as unknown as { appVersion: string };

const i18nConfig: { [key: string]: unknown } = {
    fallbackLng: "en",
    interpolation: {
        escapeValue: false
    },
    react: {
        wait: true,
        useSuspense: false
    }
};

i18nConfig.backend = {
    loadPath: `${process.env.ASSET_URL}/build/locale/{{lng}}.json?v=${
        pageProps.appVersion ?? ""
    }`
};

/**
 * i18next-http-backend plugin is used to fetch the language bundles asynchrnously
 */
i18next
    .use(Backend)
    // .use(LanguageDetector) // TODO: disabling for now since only using English and Korean. Enable later
    .use(initReactI18next)
    .init(i18nConfig);

export { i18next };
