import { defineStyleConfig } from "@chakra-ui/react";

const FormLabel = defineStyleConfig({
    baseStyle: {
        fontSize: [14, 16],
        fontFamily: "inter, sans-serif",
        color: "slateGray"
    }
});

export default FormLabel;
