import { modalAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(modalAnatomy.keys);

const baseStyle = definePartsStyle({
    dialog: {
        bg: "white",
        borderRadius: 12,
        mx: 4
    },
    header: {
        textAlign: ["left", "center"],
        fontFamily: "heading",
        fontSize: 18,
        px: 8,
        pt: 8
    },
    body: {
        px: 8,
        pt: 0,
        pb: 4,
        fontSize: 16
    },
    footer: {
        px: 8,
        pb: 8
    },
    overlay: {
        bg: "rgba(45, 55, 72, 0.56)"
    }
});

const sizes = {
    xxl: definePartsStyle({
        dialog: {
            maxW: "610px"
        }
    })
};

const Tag = defineMultiStyleConfig({
    baseStyle,
    sizes
});

export default Tag;
