import { ErrorInfo } from "react";

export const logAppError = (error: Error, info: ErrorInfo) => {
    const componentStack = info.componentStack ?? "No component stack available";

    /**
     * TODO: Do something with the error, e.g. log to an external API
     */
    console.log(error, { componentStack });
};
