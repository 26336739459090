import { createContext, useState } from "react";

import useCheckoutPageProps from "@/hooks/useCheckoutPageProps";
import { ProductFamilyInterface } from "@/ts/interfaces/Checkout/ProductFamilyInterface";

interface CheckoutContextType {
    productFamilies: ProductFamilyInterface[] | null;
    userSelectedCountry: string;
    userSelectedState: string;
    isProductFamiliesLoading: boolean;
    userSelectedLang: string;
    currency: "AUD" | "USD";

    onUpdateProductFamilies: (
        productFamilies: ProductFamilyInterface[]
    ) => void;
    onUpdateUserSelectedCountry: (country: string) => void;
    onUpdateUserSelectedState: (state: string) => void;
    onUpdateIsProductFamiliesLoading: (isLoading: boolean) => void;
    onUpdateUserSelectedLang: (lang: string) => void;
    onUpdateCurrency: (currency: "USD" | "AUD") => void;

    clearCheckoutContext: () => void;
}

interface PropTypes {
    children: React.ReactNode;
}

enum StateKey {
    USER_SELECTED_COUNTRY = "userSelectedCountry",
    USER_SELECTED_LANG = "userSelectedLang",
    USER_SELECTED_STATE = "userSelectedState",
    CURRENCY = "currency",
    PRODUCT_FAMILIES = "productFamilies"
}

export const CheckoutContext = createContext<CheckoutContextType>({
    productFamilies: null,
    userSelectedCountry: "",
    userSelectedState: "",
    currency: "AUD",
    userSelectedLang: "",
    isProductFamiliesLoading: false,

    onUpdateProductFamilies: () => {},
    onUpdateUserSelectedCountry: () => {},
    onUpdateUserSelectedState: () => {},
    onUpdateIsProductFamiliesLoading: () => {},
    onUpdateCurrency: () => {},
    onUpdateUserSelectedLang: () => {},

    clearCheckoutContext: () => {}
});

const persistState = (key: StateKey, value: string) => {
    sessionStorage.setItem(key, value);
};

const readState = (key: StateKey) => {
    return sessionStorage.getItem(key);
};

// TODO: Improve hardcorded check for contries don't need state selections
const CheckoutContextProvider = ({ children }: PropTypes) => {
    const { parentUser, availableCountries } = useCheckoutPageProps();

    const searchParams = new URLSearchParams(window.location.search);

    // By default, when below paramas are not provided, fallback values will apply.
    const selectedCountryParam = searchParams.get("selectedCountry") || "AU";

    const getLangForCountry = () => {
        return (
            availableCountries.find(o => o.value === selectedCountryParam)
                ?.languages ?? []
        );
    };

    const selectedLangParam = searchParams.get("selectedLang") ||
        getLangForCountry().filter(lang => lang.isDefault)[0]?.value ||
        "en";

    const selectedStateParam = searchParams.get("selectedState") || "";

    const defaultSelectedCountry = parentUser
        ? parentUser.countryId
        : selectedCountryParam;
    const defaultSelectedLang = parentUser
        ? parentUser.languageId
        : selectedLangParam;

    const [productFamilies, setProductFamilies] = useState<
        ProductFamilyInterface[]
    >(() => {
        let value: string;
        // Invalidate cached product families when countries are switched
        if (
            defaultSelectedCountry.toUpperCase() !==
                readState(StateKey.USER_SELECTED_COUNTRY) ||
            defaultSelectedLang.toLowerCase() !==
                readState(StateKey.USER_SELECTED_LANG)
        ) {
            value = JSON.stringify([]);
        } else {
            value = readState(StateKey.PRODUCT_FAMILIES) ?? JSON.stringify([]);
        }
        persistState(StateKey.PRODUCT_FAMILIES, value);
        return JSON.parse(value);
    });

    const [userSelectedCountry, setUserSelectedCountry] = useState(() => {
        let value: string;
        if (defaultSelectedCountry) {
            value = defaultSelectedCountry.toUpperCase();
        } else {
            value = readState(StateKey.USER_SELECTED_COUNTRY) ?? "AU";
        }
        persistState(StateKey.USER_SELECTED_COUNTRY, value);
        return value;
    });

    const [userSelectedState, setUserSelectedState] = useState(() => {
        let value: string;
        if (selectedStateParam) {
            value = selectedStateParam.toUpperCase();
        } else {
            value = readState(StateKey.USER_SELECTED_STATE) ?? "";
        }
        persistState(StateKey.USER_SELECTED_STATE, value);
        return value;
    });

    const [currency, setCurrency] = useState(() => {
        const value = readState(StateKey.CURRENCY) ?? "AUD";
        persistState(StateKey.CURRENCY, value);
        return value as "AUD" | "USD";
    });

    const [userSelectedLang, setUserSelectedLang] = useState(() => {
        let value: string;
        if (defaultSelectedLang) {
            value = defaultSelectedLang.toLowerCase();
        } else {
            value = readState(StateKey.USER_SELECTED_LANG) ?? "en";
        }
        persistState(StateKey.USER_SELECTED_LANG, value);
        return value;
    });

    const [isProductFamiliesLoading, setIsProductFamiliesLoading] =
        useState(false);

    const onUpdateProductFamilies = (
        productFamilies: ProductFamilyInterface[]
    ) => {
        setProductFamilies(productFamilies);
        persistState(
            StateKey.PRODUCT_FAMILIES,
            JSON.stringify(productFamilies)
        );
    };
    const onUpdateUserSelectedCountry = (country: string) => {
        setUserSelectedCountry(country);
        persistState(StateKey.USER_SELECTED_COUNTRY, country);
    };
    const onUpdateUserSelectedState = (state: string) => {
        setUserSelectedState(state);
        persistState(StateKey.USER_SELECTED_STATE, state);
    };

    const onUpdateUserSelectedLang = (lang: string) => {
        setUserSelectedLang(lang);
        persistState(StateKey.USER_SELECTED_LANG, lang);
    };
    const onUpdateCurrency = (currency: "AUD" | "USD") => {
        setCurrency(currency);
        persistState(StateKey.CURRENCY, currency);
    };
    const onUpdateIsProductFamiliesLoading = (isLoading: boolean) => {
        setIsProductFamiliesLoading(isLoading);
    };

    const clearCheckoutContext = () => {
        // TODO: Check for removal state
        setProductFamilies([]);
        setUserSelectedCountry("AU");
        setUserSelectedState("");
        setCurrency("AUD");
        setUserSelectedLang("en");

        sessionStorage.removeItem("productFamilies");
        sessionStorage.removeItem("userSelectedCountry");
        sessionStorage.removeItem("userSelectedState");
        sessionStorage.removeItem("userSelectedLang");
        sessionStorage.removeItem("currency");
    };

    return (
        <CheckoutContext.Provider
            value={{
                productFamilies,
                userSelectedCountry,
                userSelectedState,
                isProductFamiliesLoading,
                userSelectedLang,
                currency,
                onUpdateProductFamilies,
                onUpdateUserSelectedCountry,
                onUpdateUserSelectedState,
                onUpdateIsProductFamiliesLoading,
                onUpdateUserSelectedLang,
                onUpdateCurrency,
                clearCheckoutContext
            }}
        >
            {children}
        </CheckoutContext.Provider>
    );
};

export default CheckoutContextProvider;
